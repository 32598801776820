<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        v-if="isAuthenticated && showNav"
      >
        <AppNavigation />
      </v-navigation-drawer>

      <v-main
        v-bind:class="{
          'mb-sm-12 grey lighten-5': isAuthenticated,
          'align-center pb-0 heightfull': !isAuthenticated
        }"
      >
        <v-btn
          color="grey darken-3"
          absolute
          right
          fab
          small
          dark
          @click.stop="drawer = !drawer"
          class="d-lg-none mt-4 justify-center align-center"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

      <router-view :key="$route.fullPath" />
      <HomeDialogDe v-if="$i18n.locale == 'de' && popup" />
      <HomeDialogFr v-if="$i18n.locale == 'fr' && popup" />
      </v-main>

      <v-footer class="pa-0" app inset padless absolute width="auto">
        <AppFooterPublic @openPopup="displayDialog" class="footer" />
      </v-footer>
    </v-app>
  </div>
</template>

<script>
// import { mapGetters, mapState } from 'vuex'
import { mapGetters } from 'vuex';
import AppNavigation from '@/components/AppNavigation';
import AppFooterPublic from '@/components/AppFooterPublic';
import HomeDialogFr from './components/HomeDialogFr.vue';
import HomeDialogDe from './components/HomeDialogDe.vue';

export default {
  name: 'App',
  data() {
    return {
      username: 'dumont@bluesystem.ch',
      password: '12345677',
      drawer: true,
      // logged: false,
      showNav: true,
      popup: false
    }
  },
  components: {
    AppNavigation,
    AppFooterPublic,
    HomeDialogFr,
    HomeDialogDe,
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  mounted() {
    // this.$store.dispatch(AUTH_LOGOUT).then()
    // if (!this.isAuthenticated) {
    //   const { username, password } = this
    //   this.$store
    //     .dispatch(AUTH_REQUEST, { username, password })
    //     .then(() => {})
    //     .catch(() => {})
    // }
  },
  methods: {
    displayDialog() {
      this.$store.commit('SET_DIALOG', '0');
      this.popup = true;
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/css/color.scss';
@import 'src/assets/css/corner_color.scss';

.v-card {
  overflow: hidden;
}
.heightfull > .v-main__wrap {
  height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6,
.v-application .display-1.bigNumber {
  font-family: 'Lora', serif !important;
}
.v-main {
  padding-bottom: 123px !important;
}

h1, h2, h3, h4, h5, h6 {
  word-break: break-word !important;
}

@media screen and (max-width: 900px) {
  .title-header {
    font-size: 32px;
  }
}

.progressbar_teaser .v-progress-linear__content {
  justify-content: end !important;
  align-items: center !important;
}

@media only screen and (min-width: 1264px) {
  .footer {
    padding-left: 230px;
  }
}

</style>
